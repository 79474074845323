import React, { useEffect, useLayoutEffect, useState } from 'react';
import Loader from "./Loader";
import Logo from "./Logo";
import InstaGrid from "./InstaGrid";
import Newsletter from "./Newsletter";

const api = process.env.REACT_APP_API;

function Footer() {
    const [ footer, setHeader ] = useState([]);

    useEffect(()=>{
        fetch(`${api}/footer`)
            .then(res => res.json())
            .then((data)=>{
                setHeader(data);
            });
    }, []);

    
    useLayoutEffect(()=>{
        function init(){
            let dateElement = document.querySelector(".date");
            let year = "";

            fetch("https://worldtimeapi.org/api/timezone/America/Sao_Paulo")
                .then(res => res.json())
                .then((data)=>{
                    if(data.datetime){
                        year = (/^\d{4}/).exec(data.datetime)[0];
                    }
                });
            
            function serverClock(){
                dateElement.innerHTML = year ? year : "2020";
                // setTimeout(serverClock, 60*60*1000);
            }
            if(dateElement){
                serverClock();
            }
        }
        init();
    });

    function linkType(link){
        let phone = (/^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/).test(link);
        let email = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(link);

        if(phone) {
            return `tel:${link}`;
        }
        else if(email) {
            return `mailto:${link}`;
        }
        else {
            return link;
        }
    }


    if(!footer){
        return <Loader />;
    }
    

    return (
        <footer className="footer">
            <div className="container">
                <div className="wrap">
                    {footer.grupo ? footer.grupo.map((item, index)=>
                        <ul className={`group group-${index + 1}`} key={item.id}>
                            <li >
                                {/* Logo no Bloco 0*/ 
                                    index === 0 
                                    ? <h2 className={`footer--logo footer--title`}> <Logo /> </h2>
                                    : <h2 className="footer--title" dangerouslySetInnerHTML={{__html: item.titulo}} />
                                }
                            </li>

                            {/* Instagram condicional*/ 
                                item.titulo && item.titulo.indexOf("social") > -1
                                ? <li className="footer--instagram">
                                        <div className="instagram"> 
                                            <InstaGrid account="fohfaoi" numberOfMediaElements={6} classes={{grid: "instagram-grid", item: "instagram-item", image: "instagram-image"}} />
                                        </div>
                                    </li>
                                : null
                            }

                            {/* Newsletter condicional*/ 
                                item.titulo && item.titulo.indexOf("newsletter") > -1
                                ? <li className="footer--newsletter"> <Newsletter /> </li>
                                : null
                            }

                            {item.lista ? item.lista.map((subItem, index)=>
                                subItem.link && subItem.link.indexOf("http") > -1
                                /* Map _blank condicional*/ 
                                ?  <li className={`list list-${index + 1}`} key={subItem.id}>
                                        <a className="list--link"
                                            target="_blank noopener noreferer"
                                            title="Nova janela"
                                            href={ linkType(subItem.link) || ""} 
                                            dangerouslySetInnerHTML={{__html: subItem.item}} 
                                        />
                                    </li>
                                : <li className={`list list-${index + 1}`} key={subItem.id}>
                                    <a className="list--link"
                                        href={ linkType(subItem.link) || ""} 
                                        dangerouslySetInnerHTML={{__html: subItem.item}} 
                                    />
                                </li>                               
                            ): ""}
                        </ul>
                    ): ""}
                    </div>
            </div>
        </footer>
    );
}

export default Footer;
