import React from 'react';
import dateTextFormat from "../helpers/dateTextFormat";


function DateTime(props) {
    return (
        <div className="default-date">
            <i className="fas fa-calendar-alt"></i>
            <span className="default-date__day">{ dateTextFormat(props.date).day } </span>
            <span className="default-date__month">{ dateTextFormat(props.date).month } </span>
            <span className="default-date__year">de { dateTextFormat(props.date).year }</span>
        </div>
    );
}

export default DateTime;
