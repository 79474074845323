import React, { useEffect, useState } from 'react';

const api = process.env.REACT_APP_API;


function Logo() {
    const [ header, setHeader ] = useState([]);

    useEffect(()=>{
        fetch(`${api}/header`)
            .then(res => res.json())
            .then((data)=>{
                setHeader(data);
            });
    }, []);

    
    if(!header){
        return "";
    }

    return (
        <div className="logo">
            <a href="/">
                <span className="logo--image">{header.logo ? <img src={api+header.logo.url} alt={ header.logo.name || header.logo.alternativeText}/> : ""}</span>
                <span className="logo--text"></span>
            </a>
        </div>
    );
}

export default Logo;
