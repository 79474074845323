import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { IoIosRibbon  } from 'react-icons/io';

import Loader from "../components/Loader";
import Error from "../components/Error";

const api = process.env.REACT_APP_API;

function AniversaryMember() {
    const [ page, setPage ] = useState([]);
    const [ currentServerDate, setCurrentServerDate ] = useState([]);

    useEffect(()=>{
        fetch(`${api}/membros?_limit=-1`)
            .then(res => res.json())
            .then((data)=>{
                setPage(data);
            })
            .catch((error)=>{
                console.log("error", error);
                setPage("error");
            });

        fetch("https://worldtimeapi.org/api/timezone/America/Sao_Paulo")
            .then(res => res.json())
            .then((data)=>{
                if(data.datetime){
                    setCurrentServerDate(data.datetime);
                }
            });
    }, []);


    function calculateAge(birthdate){
        // if(birthdate){
        //     let year = birthdate ? birthdate.split("/")[2] : birthdate.split("/");
        //     let currentYear = currentServerDate ? (/^\d{4}/).exec(currentServerDate)[0] : "";
        //     let age = currentYear - year;
        //     return age || "N/A";
        // }

        
        if(birthdate){
            let currentYear = currentServerDate ? (/^\d{4}/).exec(currentServerDate)[0] : "";
            let stripDate = birthdate.split("/");
            let year = stripDate[2];
            let month = stripDate[1] - 1;
            let day = stripDate[0];
    
            let birthdateConverted = new Date(year, month, day);
            let difference = Date.now(currentYear) - birthdateConverted.getTime();
            let ageDate = new Date(difference);
            let age = (Math.abs(ageDate.getUTCFullYear() - 1970));

            return age;
        } else {
            return "N/A";
        }
    }

    function getCurrentMonth(){
        if(currentServerDate){
            let currentMonth = currentServerDate && typeof currentServerDate === "string" ? parseInt(currentServerDate.split("-")[1]) : "";
            return currentMonth;
        } else {
            return 0;
        }
    }
    function getMonthBirthdate(birthdate){
        if(birthdate){
            let stripDate = birthdate.split("/");
            let month = parseInt(stripDate[1]);
            return month;
        } else {
            return "N/A";
        }
    }
    
    if(!page && page !== "error"){
        return <Loader />;
    } else if(page === "error"){
        return <Error />;
    }


    return (
        page ? 
            <div className="aniversary-component">
                <div className="container">
                    <div className="aniversary-component--header">
                        <h2 className="title" data-title="Aniversariante do Mês">
                            {/* <i className="fas fa-birthday-cake"></i> */} 
                            Aniversariante do Mês
                        </h2>

                        <Link className="all-members" to="/aniversariante">Todos</Link>
                    </div>

                    <div className="aniversary-member__container">
                        {page && page.map((person)=>{
                                if(getMonthBirthdate(person.Nascimento) === getCurrentMonth()){
                                    return <div className="aniversary-member__card" key={person.id}>
                                        <div className="aniversary-member__image">
                                            {(person.foto[0] && 
                                                <img 
                                                    src={api + person.foto[0].url || ""} 
                                                    alt={person.foto[0].alternativeText || person.foto[0].name}
                                                />)|| <div className="no-image" alt=""/>
                                            }
                                        </div>
                                        <div className="aniversary-member__info">
                                            {person.Nascimento && 
                                                <span className="aniversary-member__date">
                                                    {person.Nascimento}
                                                </span>
                                            }
                                            <span className="aniversary-member__ribbon">
                                                <IoIosRibbon />
                                            </span>

                                            <div className="aniversary-member__nickname">{person.Apelido || ""}</div>
                                            {person.Nome && <div className="aniversary-member__name">{person.Nome}</div>}

                                            <div className="aniversary-member__position">{person.Posicao || ""}</div>

                                            {/* <div className="aniversary-member__words">{person.frase_do_jogador || ""}</div> */}
                                            
                                            <ul className="aniversary-member__career">
                                                <li className="aniversary-member__career--age">
                                                    <p>Idade</p>
                                                    <p>{currentServerDate.length && currentServerDate !== null && calculateAge(person.Nascimento)}</p>
                                                </li>
                                                {person.Naturalidade && 
                                                <li className="aniversary-member__career--matches">
                                                    {/* <p>Partidas</p>
                                                    <p>{person.Partidas || "#"}</p> */}
                                                    <p>Naturalidade</p>
                                                    <p>{person.Naturalidade || ""}</p>
                                                </li>}
                                                <li className="aniversary-member__career--goals">
                                                    <p>Gols</p>
                                                    <p>{person.Gols || "N/A"}</p>
                                                </li>
                                            </ul>

                                            <div className="aniversary-member__engagement">
                                                <span className="aniversary-member__bio">
                                                    <a href={"/"}>Biografia</a>
                                                    <i className="far fa-arrow-alt-circle-right"></i>
                                                </span>
                                                <span className="aniversary-member__follow">
                                                    <a href={person.rede_social || ""}>Siga</a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                } else {
                                    return "";
                                }
                            }
                        )}
                    </div>
                </div>
            </div>
        : ""
    );
}

export default AniversaryMember;
