import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Slider from "react-slick";

import Loader from "../components/Loader";
import Error from "../components/Error";
import DateTime from "../components/DateTime";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const api = process.env.REACT_APP_API;

function NewsUpdate(props) {
    const [ page, setPage ] = useState([]);
    const { limit } = props;

    useEffect(()=>{       
        fetch(`${api}/noticias?_sort=updated_at:ASC${limit ? "&_limit="+limit : ""}`)
            .then(res => res.json())
            .then((data)=>{
                setPage(data);
            })
            .catch((error)=>{
                console.log("error", error);
            });

    }, [limit]);
    
    if(!page && page !== "error"){
        return <Loader />;
    } else if(page === "error"){
        return <Error />;
    }

    function SampleNextArrow(props) {
        const { style, onClick } = props;
        return (
          <div
            className="arrow-proxima"
            style={{ ...style, display: "block"}}
            onClick={onClick}
          />
        );
    }
    
    function SamplePrevArrow(props) {
    const { style, onClick } = props;
    return (
        <div
        className="arrow-anterior"
        style={{ ...style, display: "block"}}
        onClick={onClick}
        />
    );
    }

    const sliderSettings = {
        arrows: true,
        prevArrow: <SamplePrevArrow />,
        nextArrow: <SampleNextArrow />,
        infinite: true,
        dots: true,
        variableWidth: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings:{
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="news news-updates">
            <div className="container-bg">
                <div className="row-news">
                    <div className="news-title">
                        <h2><Link to="/noticias">Últimas Novidades</Link></h2>
                    </div>       
                    <Slider {...sliderSettings}
                    >
                    {page && page.map((noticia, key) =>    
                        noticia.noticia_ativa ?
                            <div key={noticia.id} className="news-card">
                                <div className="news-thumb">
                                    <Link to={`/noticia/${noticia.id}`}>
                                        {(noticia.Imagem && <img 
                                                src={api + noticia.Imagem.url || ""} 
                                                alt={noticia.Imagem.alternativeText || noticia.Imagem.name}
                                            />) || <div className="no-image" alt="Sem imagem"/> }
                                        <i className="fas fa-link"></i>
                                    </Link>
                                </div> 
                                <div className="news-infos">
                                    <h4 className="news-title">
                                        <Link to={`/noticia/${noticia.id}`}>
                                            {noticia.Titulo}
                                        </Link>
                                    </h4>

                                    <DateTime date={noticia.updated_at} />
                                    
                                    <div className="news-brief">
                                    {noticia.Detalhe || "-"}
                                    </div>

                                    <div className="news-seemore">
                                        <Link to={`/noticia/${noticia.id}`}>
                                            Ver mais 
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        : ""
                        )
                    }
                    </Slider>
                </div>
            </div>
        </div>
    );
    
}
export default NewsUpdate;
