import React, {useState, useRef} from 'react';

const api = process.env.REACT_APP_API;

function Newsletter() {
    const [inputData, setInput] = useState({
        name: '',
        email: '',
        reCAPTCHA: ''
    });

    const name = useRef();
    const email = useRef();
    const reCAPTCHA = useRef();
    
    const submit = useRef();
    const messageAlert = useRef();


    async function signNewsletter(fieldData){
        let collectedData = JSON.stringify(fieldData);

        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }

        
        fetch(`${api}/lista-newsletters?email=${fieldData.email}`)
            .then(res=>res.json())
            .then((data)=>{
                if(data){
                    if(data.length){
                        let devInfo = "Dados presentes na base: "+ data;
                        let userInfo = "Email já cadastrado!";
                        
                        console.log(devInfo);
                        messageAlert.current.innerText = userInfo;
                        messageAlert.current.classList.add("alert--success", "active");
                    } else {
                        addEmailToList();
                    }
                } else {
                    addEmailToList();
                }
            })
            .catch((e)=>{
                console.log("catch", e);
                // alertError(e);
            });

        function addEmailToList(){
            fetch(`${api}/lista-newsletters`, {"method": "POST", headers, body: collectedData})
                .then((data)=>{
                    if(data.status < 400){
                        let devSuccess = "Dados enviados com sucesso: "+ data;
                        let userSuccess = "Sua mensagem foi enviada com sucesso!";
                        console.log(devSuccess);
    
                        messageAlert.current.innerText = userSuccess;
                        messageAlert.current.classList.add("alert--success", "active");
                        disableToSend();
                    } else {
                        alertError(data);
                    }
                })
                .catch((e)=>{
                    console.log("catch", e);
                    // alertError(e);
                });       
        }


    }

    

    function alertError(e){
        let devError = "Falha ao enviar os dados, " + e.error || e;
            let userMessage = "Houver algum problema ao enviar seus dados";
            console.log(devError);

            messageAlert.current.innerText = userMessage;
            messageAlert.current.classList.add("alert--error", "active");
    }


    function disableToSend(){
        submit.current.setAttribute("disabled", "disabled");
    }


    function validateEmail(value){
        let email_validation = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value);
        if(email_validation){
            return true;
        } else {
            return false;
        }
    }


    function handleChange(event){
        setInput({
            ...inputData,
            [event.target.name]: [event.target.value]
        });

        // console.log("ref", refName, refEmail);
        if(name.current.value.length > 0){
            name.current.classList.add("valid");
            name.current.classList.remove("error");
        } else {
            name.current.classList.add("error");
            name.current.classList.remove("valid");
        }
        
        if(email.current.value.length > 0 && validateEmail(email.current.value)){
            email.current.classList.add("valid");
            email.current.classList.remove("error");
        } else {
            email.current.classList.add("error");
            email.current.classList.remove("valid");
        }
        
        /*Prevenção de BOT */
        if(reCAPTCHA.current.value.length > 0){
            reCAPTCHA.current.classList.add("valid");
            reCAPTCHA.current.classList.remove("error");
        } else {
            reCAPTCHA.current.classList.add("error");
            reCAPTCHA.current.classList.remove("valid");
        }
    }

    function handleSubmit(event){
        event.preventDefault();
        setInput({
            ...inputData,
            [event.target.name]: [event.target.value]
        });

        const countInputs = document.querySelectorAll(".newsletter input, .newsletter textarea, .newsletter select");
        const checkedValidFields = document.querySelectorAll(".newsletter input.valid, .newsletter textarea.valid, .newsletter select.valid");
        const filledData = {"nome": inputData.name[0], "email": inputData.email[0], "receber": true};

        let subtract_reCAPTCHA = 1;
        if(checkedValidFields.length === (countInputs.length - subtract_reCAPTCHA)){
            signNewsletter(filledData);
        } else if(inputData.reCAPTCHA[0]) {
            console.log("Bots shall not pass");
        }
    }


    return (
        <div className="newsletter">
            <form onSubmit={handleSubmit}>
                <ul className="newsletter--wrap">
                    <li title="BOT ONLY" className="newsletter__box newsletter__box--reCAPTCHA" style={{opacity: 0, width: 0, height: 0, padding: 0, margin: 0}} tabIndex="0">
                        {/* Prevenção de BOT - INPUT falso*/}
                        <input type="text" className="form-control__reCAPTCHA" name="reCAPTCHA" placeholder="Seu Nome" ref={reCAPTCHA} onBlur={handleChange} onChange={handleChange} />
                    </li>

                    <li className="newsletter__box newsletter__box--name">
                        <input type="text" className="form-control__name" name="name" placeholder="Seu Nome" ref={name} onBlur={handleChange} onChange={handleChange} />
                    </li>
                    <li className="newsletter__box newsletter__box--email">
                        <input type="text" className="form-control__email" name="email" placeholder="Seu Email" ref={email} onBlur={handleChange} onChange={handleChange} />
                    </li>
                    <li className="newsletter__box newsletter__box--submit">
                        <strong className="">Respeitamos sua privacidade</strong>
                        <button className="form-control__submit button button-primary" ref={submit}><span>Inscreva-se</span></button>
                    </li>

                    <li className="newsletter__box newsletter__box--status">
                        <p className="form-control__status" ref={messageAlert}></p>
                    </li>
                </ul>
            </form>
        </div>
    );
}

export default Newsletter;
