import React, { useEffect, useLayoutEffect, useState } from 'react';
import Loader from "../components/Loader";
import Error from "../components/Error";

const api = process.env.REACT_APP_API;

function HallOfFame() {
    const [ page, setPage ] = useState([]);
    const [ currentServerDate, setCurrentServerDate ] = useState([]);

    useEffect(()=>{
        fetch(`${api}/hall-da-famas`)
            .then(res => res.json())
            .then((data)=>{
                setPage(data);
            })
            .catch((error)=>{
                console.log("error", error);
                setPage("error");
            });

        fetch("https://worldtimeapi.org/api/timezone/America/Sao_Paulo")
            .then(res => res.json())
            .then((data)=>{
                if(data.datetime){
                    setCurrentServerDate(data.datetime);
                }
            });
    }, []);


    function calculateAge(birthdate){
        // if(birthdate){
        //     let year = birthdate ? birthdate.split("/")[2] : birthdate.split("/");
        //     let currentYear = currentServerDate ? (/^\d{4}/).exec(currentServerDate)[0] : "";
        //     let age = currentYear - year;
        //     return age || "-";
        // }


        
        if(birthdate){
            let currentYear = currentServerDate ? (/^\d{4}/).exec(currentServerDate)[0] : 2020;
            let stripDate = birthdate.split("/");
            let year = stripDate[2];
            let month = stripDate[1] - 1;
            let day = stripDate[0];
    
            let birthdateConverted = new Date(year, month, day);
            let difference = Date.now(currentYear) - birthdateConverted.getTime();
            let ageDate = new Date(difference);
            let age = (Math.abs(ageDate.getUTCFullYear() - 1970));

            return age;
        } else {
            return "-";
        }
    }

    

    useLayoutEffect(()=>{
        const createModal = () =>{
            let modal = document.createElement("div");
            let modalWrap = document.createElement("div");
            let close = document.createElement("div");
            let closeIcon = document.createElement("i");
            modal.classList.add("modal-player");
            modalWrap.classList.add("modal-player__wrap");
            closeIcon.classList.add("fas", "fa-times");
            close.classList.add("modal-player__close");
            
            modal.append(modalWrap);
            close.append(closeIcon);
            modal.append(close);
            document.body.prepend(modal);
        };

        createModal();
    }, []);


    function openModal(event){
        // event.preventDefault();
        let element = event.target;
        let modalContainer = document.querySelector(".modal-player");
        let closeModal = document.querySelector(".modal-player__close");
        // document.body.style.overflow = "hidden";
        
        if(element && modalContainer){
            let cloneElement = element.closest(".player__card").cloneNode(true);
            let modalContent = modalContainer.querySelector(".modal-player__wrap");
            modalContent.replaceChildren(cloneElement);
            modalContainer.classList.add("active");
            document.body.classList.add("no-overflow");
            
            if(closeModal){
                closeModal.onclick = function(){
                    modalContainer.classList.remove("active");
                    document.body.classList.remove("no-overflow");
                }
            }
        }
    }
    


    if(!page && page !== "error"){
        return <Loader />;
    } else if(page === "error"){
        return <Error />;
    }


    return (
        page ? 
            <div className="hall-component">
                <div className="container">
                    <div className="hall-component--header">
                        <h2 className="title">
                            Hall da Fama
                        </h2>
                        <a className="full-team" href="/equipe">Esquadrão completo</a>
                    </div>
                    <div className="player__container">
                        {page && page.map((person, index)=>
                            <div className="player__card" key={person.id}>
                                <div className="player__image">
                                    {(person.Jogador.jogador.foto[0] && 
                                        <img 
                                            src={api + person.Jogador.jogador.foto[0].url || ""} 
                                            alt={person.Jogador.jogador.foto[0].alternativeText || person.Jogador.jogador.foto[0].name}
                                        />)|| <div className="no-image" alt="Jogador sem imagem"/>
                                    }
                                </div>
                                <div className="player__info">
                                    <span className="player__tag">
                                        <i className="fas fa-star"></i>
                                    </span>

                                    <div className="player__name">{person.Jogador.jogador.Nome || ""}</div>
                                    <div className="player__position">{person.Jogador.jogador.Posicao || ""}</div>

                                    <div className="player__words">{person.frase_do_jogador || ""}</div>
                                    
                                    <ul className="player__career">
                                        <li className="player__career--age">
                                            <p>Idade</p>
                                            <span className="colon">:</span>
                                            <p>{currentServerDate.length && currentServerDate !== null && calculateAge(person.Jogador.jogador.Nascimento)}</p>
                                        </li>
                                        <li className="player__career--matches">
                                            <p>Partidas</p>
                                            <span className="colon">:</span>
                                            <p>{person.Jogador.jogador.Partidas || "-"}</p>
                                        </li>
                                        <li className="player__career--goals">
                                            <p>Gols</p>
                                            <span className="colon">:</span>
                                            <p>{person.Jogador.jogador.Gols || "-"}</p>
                                        </li>


                                        <li className="player__career--realizacoes type-modal">
                                            <p>Realizações</p>
                                            <span className="colon">:</span>
                                            <p>{person.realizacoes || "-"}</p>
                                        </li>


                                        {/* <li className="player__career--birthplace type-modal">
                                            <p>Naturalidade</p>
                                            <span className="colon">:</span>
                                            <p>{person.Jogador.jogador.Naturalidade || "-"}</p>
                                        </li>
                                        <li className="player__career--height type-modal">
                                            <p>Altura</p>
                                            <span className="colon">:</span>
                                            <p>{person.Jogador.jogador.Altura || "-"}</p>
                                        </li>
                                        <li className="player__career--peso type-modal">
                                            <p>Peso</p>
                                            <span className="colon">:</span>
                                            <p>{person.Jogador.jogador.Peso || "-"}</p>
                                        </li>
                                        <li className="player__career--kick type-modal">
                                            <p>Chute</p>
                                            <span className="colon">:</span>
                                            <p>{person.Jogador.jogador.Chute || "-"}</p>
                                        </li>
                                        <li className="player__career--cheering type-modal">
                                            <p>Time que torce</p>
                                            <span className="colon">:</span>
                                            <p>{person.Jogador.jogador.time_que_torce || "-"}</p>
                                        </li>
                                        <li className="player__career--godfather type-modal">
                                            <p>Padrinho</p>
                                            <span className="colon">:</span>
                                            <p>{person.Jogador.jogador.Padrinho || "-"}</p>
                                        </li>
                                        <li className="player__career--entrance type-modal">
                                            <p>Data de entrada</p>
                                            <span className="colon">:</span>
                                            <p>{person.Jogador.jogador.data_de_entrada || "-"}</p>
                                        </li>
                                        <li className="player__career--status type-modal">
                                            <p>Status</p>
                                            <span className="colon">:</span>
                                            <p>{person.Jogador.jogador.Status || "-"}</p>
                                        </li> */}
                                    </ul>

                                    <div className="player__engagement">
                                        <button className="player__bio" onClick={openModal}>
                                            <span >Realizações</span>
                                            <i className="far fa-arrow-alt-circle-right"></i>
                                        </button>
                                        <span className="player__follow">
                                            <a href={person.rede_social || ""} target="_new">Siga</a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        : ""
    );
}

export default HallOfFame;
