import React from 'react';

// import React, { useEffect, useState } from 'react';
// const api = process.env.REACT_APP_API;

function Teste() {
    // const [ home, setHome ] = useState([]);

    // useEffect(()=>{
    //     fetch(`${api}/home`)
    //         .then(res => res.json())
    //         .then((data)=>{
    //             setHome(data);
    //         });
    // }, []);
    
    // if(!home){
    //     return;
    // }

    return (
        <div className="teste">
            <div className="container">
                <section className="section section-1">
                    section teste
                </section>
            </div>
        </div>
    );
}

export default Teste;
