import React, { useEffect, useState, memo } from 'react';
import { useDebounce } from 'use-debounce';
import Loader from "../components/Loader";
import Error from "../components/Error";

const api = process.env.REACT_APP_API;

function Team() {
    const screenSize = window.innerWidth;
    const [ page, setPage ] = useState([]);
    const [ totalMembers, setTotalMembers ] = useState([]);
    const [ totalActiveMembers, setActiveMembers ] = useState(0);
    const [ totalInactiveMembers, setInactiveMembers ] = useState(0);
    const [ totalDeadMembers, setDeadMembers ] = useState(0);
    const [ limit, setLimit ] = useState(screenSize < 768 ? 10 : 200);
    const [ searchQuery, setSearchQuery ] = useState("");
    const [ debounce_searchQuery ] = useDebounce(searchQuery, 300);
    // const [ currentServerDate, setCurrentServerDate ] = useState([]);

    useEffect(()=>{
        /* Main Requests */
        fetch(`${api}/membros?_sort=apelido:ASC&_limit=${limit}` + (debounce_searchQuery ? `&_where[_or][Apelido_contains]=${debounce_searchQuery}&_where[_or][Nome_contains]${debounce_searchQuery}` : ""))
        .then(res => res.json())
        .then((data)=>{
            setPage(data);
        })
        .catch((error)=>{
            console.log("error", error);
            setPage("error");
        });

        
        /* Resource Counters */
        fetch(`${api}/membros/count?_sort=apelido:ASC&_limit=-1`)
            .then(res => res.json())
            .then((data)=>{
                setTotalMembers(data);
            })
            .catch((error)=>{
                console.log("error", error);
                setTotalMembers("error");
            });

        fetch(`${api}/membros/count?_sort=apelido:ASC&_limit=-1&_where[Status]=Ativo`)
            .then(res => res.json())
            .then((data)=>{
                setActiveMembers(data);
            })
            .catch((error)=>{
                console.log("error", error);
                setActiveMembers("-");
            });

        fetch(`${api}/membros/count?_sort=apelido:ASC&_limit=-1&_where[Status]=Inativo`)
            .then(res => res.json())
            .then((data)=>{
                setInactiveMembers(data);
            })
            .catch((error)=>{
                console.log("error", error);
                setInactiveMembers("-");
            });

        fetch(`${api}/membros/count?_sort=apelido:ASC&_limit=-1&_where[Status]=Falecido`)
            .then(res => res.json())
            .then((data)=>{
                setDeadMembers(data);
            })
            .catch((error)=>{
                console.log("error", error);
                setDeadMembers("-");
            });


        // fetch("http://worldtimeapi.org/api/timezone/America/Sao_Paulo")
        //     .then(res => res.json())
        //     .then((data)=>{
        //         if(data.datetime){
        //             setCurrentServerDate(data.datetime);
        //         }
        //     });        
        
    }, [limit, searchQuery, debounce_searchQuery]);


    const loadMore = () => {
        setLimit( limit + (screenSize < 768 ? 10 : 200) );
    }
    
    if((!page && page !== "error")){
        return <Loader />;
    } else if(page === "error"){
        return <Error />;
    }


    return (
        page ? 
            <div className="team-component">
                <div className="container-bg">
                    <div className="team-component--header">
                        <div className="title" data-title="Equipe">
                            <p className="text">Equipe</p>
                            {(totalActiveMembers && totalInactiveMembers) && 
                            <span className="members-quantity total-quantity__members" style={{display: "block"}}>
                                Total de membros: {totalMembers} {/* {totalActiveMembers + totalInactiveMembers} */}
                            </span>}

                            <div className="search-member">
                                <form>
                                    <input className="search-member__field" id="search-member-input"  placeholder="Buscar membro" onChange={ e => {setSearchQuery(e.target.value)} } />
                                    <button className="search-member__submit">
                                        Buscar
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="team__container">
                        
                        <div className="active-members">
                            <h3 className="subtitle">
                                Membros Ativos 
                                <a className="anchor anchor-members-1" href="#inactive-members">Já Passaram Por Aqui <i className="fas fa-long-arrow-alt-down"></i></a> 
                                <span className="anchor__offset target" id="active-members"></span>
                                {totalActiveMembers && <span className="members-quantity members-quantity__active">Total: {totalActiveMembers}</span>}
                            </h3>
                            
                            <div className="members-container">
                                {page && page.map((person)=> {
                                        if((person.Status && person.Status.toLowerCase()) === "ativo"){
                                            return <div className="team__card " key={person.id}>
                                                <div className="team__image">
                                                    {(person.foto[0] && 
                                                        <img 
                                                            src={api + person.foto[0].url || ""} 
                                                            alt={person.foto[0].alternativeText || person.foto[0].name}
                                                        />)|| <div className="no-image" alt="Foto Indisponível"/>
                                                    }
                                                </div>
                                                <div className="team__info">
                                                    {person.Apelido && 
                                                        <span className="team__nickname">
                                                            {person.Apelido}
                                                        </span>
                                                    }
                                                    <span className="team__ribbon">
                                                        <i className="fas fa-user"></i>
                                                    </span>

                                                    {/* {person.Padrinho && <div className="team__godfather">{person.Padrinho}</div>} */}
                                                    {person.Nome && <div className="team__name">{person.Nome}</div>}

                                                    <div className="team__position">{person.Posicao || ""}</div>

                                                    {/* <div className="team__words">{person.frase_do_jogador || ""}</div> */}
                                                    
                                                    <ul className="team__career">
                                                        {/* <li className="team__career--age">
                                                            <p>Idade</p>
                                                            <p>{currentServerDate.length && currentServerDate !== null && calculateAge(person.Nascimento)}</p>
                                                        </li> */}
                                                        <li className="team__career--joined">
                                                            <p>Data de entrada</p>
                                                            <p>{person.data_de_entrada || <span className="no-info">--</span>}</p>
                                                        </li>
                                                        <li className="team__career--godfather">
                                                            <p>Padrinho</p>
                                                            <p>{person.Padrinho || <span className="no-info">--</span>}</p>
                                                        </li>
                                                        <li className="team__career--matches">
                                                            {/* <p>Partidas</p>
                                                            <p>{person.Partidas || "#"}</p> */}
                                                            <p>Naturalidade</p>
                                                            <p>{person.Naturalidade || <span className="no-info">--</span>}</p>
                                                        </li>
                                                    </ul>

                                                    <div className="team__engagement">
                                                        <span className="team__bio">
                                                            <a href={"/"}>Biografia</a>
                                                            <i className="far fa-arrow-alt-circle-right"></i>
                                                        </span>
                                                        <span className="team__follow">
                                                            <a href={person.rede_social || ""}>Siga</a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        } else {
                                            return "";
                                        }
                                    }
                                )}
                            </div>
                        </div>

                        <div className="inactive-members">
                            <h3 className="subtitle">
                                Já passaram por aqui
                                <a className="anchor anchor-members-2" href="#in-memory">Membros Ativos <i className="fas fa-long-arrow-alt-down"></i></a> 
                                <span className="anchor__offset target" id="inactive-members"></span>
                                {totalInactiveMembers && <span className="members-quantity members-quantity__inactive">Total: {totalInactiveMembers}</span>}
                            </h3>

                            <div className="members-container">
                                {page && page.map((person)=> {
                                        if((person.Status && person.Status.toLowerCase()) !== "ativo"){
                                            return <div className="team__card " key={person.id}>
                                                <div className="team__image">
                                                    {(person.foto[0] && 
                                                        <img 
                                                            src={api + person.foto[0].url || ""} 
                                                            alt={person.foto[0].alternativeText || person.foto[0].name}
                                                        />)|| <div className="no-image" alt=""/>
                                                    }
                                                </div>
                                                <div className="team__info">
                                                    {person.Apelido && 
                                                        <span className="team__nickname">
                                                            {person.Apelido}
                                                        </span>
                                                    }
                                                    <span className="team__ribbon">
                                                        <i className="fas fa-user-slash"></i>
                                                    </span>

                                                    {/* {person.Padrinho && <div className="team__godfather">{person.Padrinho}</div>} */}
                                                    {person.Nome && <div className="team__name">{person.Nome}</div>}

                                                    <div className="team__position">{person.Posicao || ""}</div>

                                                    {/* <div className="team__words">{person.frase_do_jogador || ""}</div> */}
                                                    
                                                    <ul className="team__career">
                                                        {/* <li className="team__career--age">
                                                            <p>Idade</p>
                                                            <p>{currentServerDate.length && currentServerDate !== null && calculateAge(person.Nascimento)}</p>
                                                        </li> */}
                                                        <li className="team__career--left">
                                                            <p>Data de saída</p>
                                                            <p>{person.data_de_afastamento || <span className="no-info">--</span>}</p>
                                                        </li>
                                                        {<li className="team__career--godfather">
                                                            <p>Padrinho</p>
                                                                <p>{person.Padrinho || <span className="no-info">--</span>}</p>
                                                        </li>}
                                                        <li className="team__career--matches">
                                                            {/* <p>Partidas</p>
                                                            <p>{person.Partidas || "#"}</p> */}
                                                            <p>Naturalidade</p>
                                                            <p>{person.Naturalidade || <span className="no-info">--</span>}</p>
                                                        </li>
                                                    </ul>

                                                    <div className="team__engagement">
                                                        <button className="team__bio">
                                                            <span>Realizações</span>
                                                            <i className="far fa-arrow-alt-circle-right"></i>
                                                        </button>
                                                        <span className="team__follow">
                                                            <a href={person.rede_social || ""}>Siga</a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        } else {
                                            return "";
                                        }
                                    }
                                )}
                            </div>
                        </div>

                        <div className="in-memory">
                            <h3 className="subtitle">
                                In Memory
                                <a className="anchor anchor-members-3" href="#active-members">Membros Inativos <i className="fas fa-long-arrow-alt-up"></i></a> 
                                <span className="anchor__offset target" id="in-memory"></span>
                                {totalDeadMembers && <span className="members-quantity members-quantity__inactive" style={{visibility: "hidden"}}>Total: {totalDeadMembers}</span>}
                            </h3>

                            <div className="members-container">
                                {page && page.map((person)=> {
                                        if((person.Status && person.Status.toLowerCase()) === "falecido"){
                                            return <div className="team__card " key={person.id}>
                                                <div className="team__image">
                                                    {(person.foto[0] && 
                                                        <img 
                                                            src={api + person.foto[0].url || ""} 
                                                            alt={person.foto[0].alternativeText || person.foto[0].name}
                                                        />)|| <div className="no-image" alt=""/>
                                                    }
                                                </div>
                                                <div className="team__info">
                                                    {person.Apelido && 
                                                        <span className="team__nickname">
                                                            {person.Apelido}
                                                        </span>
                                                    }
                                                    <span className="team__ribbon">
                                                        <i className="fas fa-user-slash"></i>
                                                    </span>

                                                    {/* {person.Padrinho && <div className="team__godfather">{person.Padrinho}</div>} */}
                                                    {person.Nome && <div className="team__name">{person.Nome}</div>}

                                                    <div className="team__position">{person.Posicao || ""}</div>

                                                    {/* <div className="team__words">{person.frase_do_jogador || ""}</div> */}
                                                    
                                                    <ul className="team__career">
                                                        {/* <li className="team__career--age">
                                                            <p>Idade</p>
                                                            <p>{currentServerDate.length && currentServerDate !== null && calculateAge(person.Nascimento)}</p>
                                                        </li> */}
                                                        <li className="team__career--left">
                                                            <p>Data de saída</p>
                                                            <p>{person.data_de_afastamento || <span className="no-info">--</span>}</p>
                                                        </li>
                                                        {<li className="team__career--godfather">
                                                            <p>Padrinho</p>
                                                                <p>{person.Padrinho || <span className="no-info">--</span>}</p>
                                                        </li>}
                                                        <li className="team__career--matches">
                                                            {/* <p>Partidas</p>
                                                            <p>{person.Partidas || "#"}</p> */}
                                                            <p>Naturalidade</p>
                                                            <p>{person.Naturalidade || <span className="no-info">--</span>}</p>
                                                        </li>
                                                    </ul>

                                                    <div className="team__engagement">
                                                        <button className="team__bio">
                                                            <span>Realizações</span>
                                                            <i className="far fa-arrow-alt-circle-right"></i>
                                                        </button>
                                                        <span className="team__follow">
                                                            <a href={person.rede_social || ""}>Siga</a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        } else {
                                            return "";
                                        }
                                    }
                                )}
                            </div>
                        </div>

                    </div>
                    <div className="load-more__container">
                        {limit < totalMembers 
                            ? <button className="load-more__button button button-primary" type="button" onClick={loadMore}>Carregar mais</button> 
                            : <button className="load-more__button button button-primary" type="button" disabled>Todos Carregados</button>}
                    </div>
                </div>
            </div>
        : ""
    );
}

export default memo(Team);
