import React, { useMemo, useEffect, useState, useRef } from 'react';
import { Route, NavLink } from "react-router-dom";
import Loader from "./Loader";
import Logo from "./Logo";
import SocialNetworks from "./SocialNetworks";


const api = process.env.REACT_APP_API;

function Header() {
    const [ header, setHeader ] = useState([]);

    useMemo(()=>{
        fetch(`${api}/header`)
            .then(res => res.json())
            .then((data)=>{
                setHeader(data);
            });
    }, []);

    
    const menuRef = useRef();
    const handleOutSide = e => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
            menuRef.current.classList.remove("active");
        }
    };

    useEffect(()=>{        
        document.addEventListener("click", handleOutSide);
        return () => {
            document.removeEventListener("click", handleOutSide);
        };
    });

    function activateMenu(event){
        let timeout = event.currentTarget.classList.contains("js-bar");
        
        if(window.innerWidth < 1024){
            if(menuRef.current){
                setTimeout(()=>{
                    menuRef.current.classList.toggle("active");
                }, timeout ? 0 : 1000);
            }
        }
    }

    
    if(!header){
        return <Loader />;
    }

    return (
        <header className="header" ref={menuRef}>
            <div className="topbar">
                <div className="container">
                    <SocialNetworks />
                </div>
            </div>

            <div className="container">
                <div className="nav-bar"> 
                    <Logo />
                    <nav className="nav js-nav">
                        <div className="bar-button js-bar" onClick={activateMenu}>
                            <i className="fa fa-bars"></i>
                            <i className="fa fa-times"></i>
                        </div>
                        <ul className="menu">
                            {
                                header.menu ? 
                                    header.menu.map((menu)=>
                                        <Route path={menu.link} exact key={menu.id}>
                                            {({match}) =>
                                               menu.text.indexOf("patrocinio") > -1 
                                                    ? <li  className={`${match && match.url === menu.link ? "active " : ""}patrocinio`} onClick={activateMenu}> 
                                                        <NavLink to={menu.link} dangerouslySetInnerHTML={{__html: menu.text || ""}}/> 
                                                    </li>
                                                    : <li className={match && match.url === menu.link ? "active " : ""} onClick={activateMenu}>
                                                        <NavLink to={menu.link || "#"} >
                                                            {menu.text || ""}
                                                        </NavLink>
                                                    </li>
                                            }
                                        </Route>
                                    ) : ""
                            }
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    );
}

export default Header;
