import React, { useEffect, useState } from 'react';

const api = process.env.REACT_APP_API;


function SocialNetworks() {
    const [ assets, setAssets ] = useState([]);

    useEffect(()=>{
        fetch(`${api}/social-networks`)
            .then(res => res.json())
            .then((data)=>{
                setAssets(data);
            });
    }, []);

    
    if(!assets){
        return "";
    }

    return (
        <ul className="social">
            {assets.networks ? assets.networks.map((item)=>{
                    switch(item.nome){
                        case "facebook":
                            return <li className="facebook" key={item.id}>
                                <a href={item.link } target="_new"><i className="fab fa-facebook-f"></i></a>
                            </li>
                        case "instagram":
                            return <li className="instagram" key={item.id}>
                                <a href={item.link} target="_new"><i className="fab fa-instagram"></i></a>
                            </li>
                        case "youtube":
                            return <li className="youtube" key={item.id}>
                                <a href={item.link} target="_new"><i className="fab fa-youtube"></i></a>
                            </li>
                        default:
                            return ""
                    }
                }
            ): ""}
        </ul>
    );
}

export default SocialNetworks;
