function setMobileVersion(target_element) {
    let element = document.querySelector(target_element);

    let mobile = ["mobile", "js-mobile" ];
    let tablet = ["tablet", "js-tablet"];

    
    if(!element){
        return;
    }

    if (window.innerWidth < 768) {
        element.classList.add(...mobile);
    } else {
        element.classList.remove(...mobile);
    }

    if (window.innerWidth > 768 && window.innerWidth < 1024) {
        element.classList.add(...tablet);
    } else {
        element.classList.remove(...tablet);
    }
}

export { setMobileVersion };