import React, { useEffect, useState } from 'react';

import Loader from "../components/Loader";
import Error from "../components/Error";


const api = process.env.REACT_APP_API;

function Sponsors(props) {
    const [ page, setPage ] = useState([]);
    const { limit } = props;

    useEffect(()=>{       
        fetch(`${api}/patrocinadores?_sort=updated_at:ASC`)
            .then(res => res.json())
            .then((data)=>{
                setPage(data);
            })
            .catch((error)=>{
                console.log("error", error);
            });

    }, [limit]);
    
    if(!page && page !== "error"){
        return <Loader />;
    } else if(page === "error"){
        return <Error />;
    }



    return (
        <div className="container-bg">
            <div className="sponsors sponsors-page">
                <div className="title">
                    <h2>Patrocinadores</h2>
                </div>

                <ul className="sponsors-list">
                    {page && page.map((sponsors, key) =>    
                        sponsors.ativo ?
                            <li key={sponsors.id} className="sponsors-bar" title={sponsors.nome}>
                                <div className="sponsors-thumb">
                                    {(sponsors.imagem && <img 
                                            src={api + sponsors.imagem.url || ""} 
                                            alt={sponsors.imagem.alternativeText || sponsors.imagem.name}
                                        />) || <div className="no-image" alt="Sem imagem"/> }
                                </div> 
                                <div className="sponsors-details">
                                    <span className="sponsors-logo">
                                        {(sponsors.logo && <img 
                                                src={api + sponsors.logo.url || ""} 
                                                alt={sponsors.logo.alternativeText || sponsors.logo.name}
                                            />) || <div className="no-image" alt="Sem imagem"/> }
                                    </span>
                                    {/* <span className="sponsors-name" to={`/sponsors/${sponsors.id}`}>
                                        {sponsors.nome}
                                    </span> */}
                                </div>
                            </li>
                        : ""
                        )
                    }
                </ul>
            </div>
        </div>
    );
    
}
export default Sponsors;
