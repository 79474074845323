import * as React from 'react';
import withInstagramFeed from 'origen-react-instagram-feed';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import compose from 'recompose/compose';
 
const styles = () => ({
  wrapper: {},
  item: {},
  grid: {},
  image: {
    width: '100%',
    height: 'auto',
  },
});
 
export type Props = {
  media?: Array<{
    displayImage: string,
    id?: string,
    postLink?: string,
    accessibilityCaption?: string,
  }>,
  account: string,
  classes: { [$Keys<$Call<typeof styles>>]: string },
  status: 'completed' | 'loading' | 'failed',
};
 
const InstaGrid = ({ classes, media, account, status}: Props) => {
  return (
    <Grid container spacing={0} className={`${classes.wrapper} ${classes.grid}`}>
      {media &&
        status === 'completed' &&
        media.map(({ displayImage, id, postLink, accessibilityCaption }, index) => (
          <Grid item xs={12} sm={6} md={4} key={id || displayImage} className={`${classes.item} ${classes.item}-${index + 1}`} >
            <ButtonBase
              href={postLink || `https://www.instagram.com/${account}/`}
            >
              <img
                src={displayImage}
                alt={accessibilityCaption || 'Instagram picture'}
                className={classes.image}
              />
            </ButtonBase>
          </Grid>
        ))}
      {status === 'loading' && <p>loading...</p>}
      {status === 'failed' 
      && <a target="_new" href={`https://www.instagram.com/${account}`} style={{gridArea:"1/1 / 1/4"}} title={`Carregamento falhou: ${status}, abrir em nova aba`}>Acessar Instagram</a>}
    </Grid>
  );
};
 
InstaGrid.defaultProps = {
  media: undefined,
};
 
export default compose(
  withInstagramFeed,
  withStyles(styles),
)(InstaGrid);