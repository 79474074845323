import zeroPad from "./zeroPad";


export default function dateTextFormat(date){
    let dateFormat = new Date(date);

    return {
        day: zeroPad(dateFormat.getDate()),
        month: dateFormat.toLocaleString('pt-br', { month: 'long' }).substring(0, 3),
        year: dateFormat.getFullYear(),
        fullDate: dateFormat.toLocaleDateString(),
        fullTime: dateFormat.toLocaleTimeString(),
        fullDateTime: dateFormat.toLocaleString()
    }
}