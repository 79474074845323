import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import ReadMoreNews from "../NewsList";
import DateTime from "../../components/DateTime";


const api = process.env.REACT_APP_API;



function NewsSingle() {
    let { slug } = useParams();
    const [ page, setPage ] = useState([]);
    
    useEffect(()=>{
        //get id on load
        fetch(`${api}/noticias/${slug}`)
            .then(res => res.json())
            .then((data)=>{
                setPage(data);
            })
            .catch((error)=>{
                console.log("error", error);
            });
    }, [slug]);


    return (
        <>
            <div className="container">
                <div className="news-group">
                    <div className="news news-single">
                        {
                            page.noticia_ativa ?
                                <div className="news-bar">
                                    <div className="news-thumb">                                    
                                        {(page.Imagem &&
                                            <img 
                                                src={api + page.Imagem.url || ""} 
                                                alt={page.Imagem.alternativeText || page.Imagem.name}
                                            />) || <div className="no-image" alt="Notícia sem imagem"/>
                                        }
                                    </div>                               

                                    <div className="news-infos">
                                        <h4>
                                            <Link to={`/noticia/${page.id}`} >
                                                {page.Titulo}
                                            </Link>
                                        </h4>
                                        
                                        <DateTime date={page.updated_at}/>

                                        <div className="news-details">
                                            <ReactMarkdown source={page.Detalhe} />
                                        </div>

                                        <div className="news-controll">
                                            {/* <button className="news-button__prev news-navigation" id="-" onClick={ newsUpdate }>
                                                <i className="fas fa-chevron-left"></i>
                                            </button>
                                            <button className="news-button__next news-navigation" id="+" onClick={ newsUpdate }>
                                                <i className="fas fa-chevron-right"></i>
                                            </button> */}
                                        </div>
                                    </div>

                                </div>
                            : ""
                        }
                    </div>

                    <ReadMoreNews slug={slug} limit={7} classes="news-extra" />
                </div>
            </div>
        </>
    );
}

export default NewsSingle;
