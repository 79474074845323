import React, { useMemo, useState, useLayoutEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const api = process.env.REACT_APP_API;

function Head() {
    
    function InfoLocation() {
        let location = useLocation();
        let currentLocation = location.pathname && location.pathname.split("/").length > 0 ? location.pathname.split("/").splice(-1) : null;
        let prefix = "page-"+currentLocation;

        useLayoutEffect(() => {
            if(document.querySelector("body")) {
                if(location.pathname === "/") {
                    document.querySelector("body").classList.add("home");
                    document.querySelector("body").classList.remove(prefix);
                } else {
                    if(currentLocation.length){
                        document.querySelector("body").classList.add(prefix);
                    }
                }
                return () => {
                    if(document.querySelector("body").classList.contains(prefix)){
                        document.querySelector("body").classList.remove(prefix);
                    }
                    if(document.querySelector("body").classList.contains("home")){
                        document.querySelector("body").classList.remove("home");
                    }
                }
            }
        }, [currentLocation, prefix, location.pathname]);
        
    }
    
    InfoLocation();


    const [ head, setHead ] = useState([]);

    useMemo(()=>{
        fetch(`${api}/head`)
            .then(res => res.json())
            .then((data)=>{
                setHead(data);
            });
    }, []);

    
    if(!head){
        return "";
    }

    return (
        <HelmetProvider>
            <Helmet>
                <meta name="description" content={head.meta_description || "Fohfa Oi - Sociedade Esportia de amigos."} />

                <meta name="keywords" content={head.keywords || "Fohfa Oi, Oi Fohfa, Futebol Clube, amigos"} ></meta>
                <title>{head.titulo_pagina || "Fohfa Oi - Sociedade Espoertiva de Amigos."}</title>

                {head.favicon && <link rel="icon" href={api + head.favicon.url} />}

                <link rel="canonical" href={head.website_address || "https://www.fohfaoi.com"} />
            </Helmet>
        </HelmetProvider>
    );
}

export default Head;
