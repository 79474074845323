import React, {useEffect, useState} from "react";
import lineBall from "../assets/image/sp.png";


const api = process.env.REACT_APP_API;

function Season() {
    const [ page, setPage ] = useState([]);

    useEffect(()=>{
        fetch(`${api}/temporadas`)
            .then(res => res.json())
            .then((data)=>{
                setPage(data);
            })
            .catch((error)=>{
                console.log("error", error);
            });
    }, []);


    return (
        <div className="container-bg">
            <div className="season">
                <ul className="season-list">
                    {
                    page && page.map((season, id) =>
                        <li className="season-item" key={id}>
                            <div className="team team-A">
                                <div className="team-info">
                                    <div className="team-logo">
                                        {season.temporada_time_1.logo_time 
                                            ? <img src={api + season.temporada_time_1.logo_time.url} alt={api + season.temporada_time_1.logo_time.name || api + season.temporada_time_1.logo_time.alternativeText}/>
                                        : <div className="no-image" />}
                                    </div>
                                    <span className="team-name">{season.temporada_time_1.nome_time}</span>
                                </div>
                                <div className="team-score">
                                    <strong>{season.temporada_time_1.lista_de_gols.reduce((accumulator, currentValue)=> accumulator + currentValue.gols, 0)}</strong>

                                    {season.temporada_time_1.lista_de_gols.reduce((accumulator, currentValue)=> accumulator + currentValue.gols, 0) > season.temporada_time_2.lista_de_gols.reduce((accumulator, currentValue)=> accumulator + currentValue.gols, 0) 
                                        ? <span className="win">Venceu</span>
                                        : <span className="loss">Perdeu</span>}
                                </div>

                                <ul className="score-list">
                                    {season.temporada_time_1.lista_de_gols.map((score, id)=>
                                        <li key={id}>
                                            <span className="score-list__name">{score.nome} </span>
                                            <span className="score-list__gols">({score.gols} gols)</span>
                                        </li>
                                    )}
                                </ul>
                            </div>

                            <div className="info">
                                <strong>{season.nome_campeonato}</strong>
                                <p className="brief">{season.breve_descricao}</p>
                                <img src={lineBall} alt="Placar divisão bola" className="line"/>
                                <p>{ new Date(season.data).toLocaleDateString() }</p>
                                <p>{season.local}</p>
                                <p>{season.nome_capeonato}</p>
                            </div>
                            <img src={lineBall} alt="Placar divisão bola" className="line-mobile"/>

                            <div className="team team-B">
                                <div className="team-info">
                                    <div className="team-logo">
                                        {season.temporada_time_2.logo_time 
                                            ? <img src={api + season.temporada_time_2.logo_time.url} alt={api + season.temporada_time_2.logo_time.name || api + season.temporada_time_2.logo_time.alternativeText}/>
                                        : <div className="no-image" />}
                                    </div>
                                    <span className="team-name">{season.temporada_time_2.nome_time}</span>
                                </div>
                                <div className="team-score">
                                    <strong>{season.temporada_time_2.lista_de_gols.reduce((accumulator, currentValue)=> accumulator + currentValue.gols, 0)}</strong>
                                    
                                    {season.temporada_time_1.lista_de_gols.reduce((accumulator, currentValue)=> accumulator + currentValue.gols, 0) < season.temporada_time_2.lista_de_gols.reduce((accumulator, currentValue)=> accumulator + currentValue.gols, 0) 
                                        ? <span className="win">Venceu</span>
                                        : <span className="loss">Perdeu</span>}
                                </div>

                                <ul className="score-list">
                                    {season.temporada_time_2.lista_de_gols.map((score, id)=>
                                        <li key={id}>
                                            <span className="score-list__name">{score.nome} </span>
                                            <span className="score-list__gols">({score.gols} gols)</span>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </li>)
                    }
                </ul>
            </div>
        </div>
    );
}

export default Season;