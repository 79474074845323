import zeroPad from "./zeroPad";

export default function dateCompare(diff) {
    // "round" to the nearest whole number, 
    // "diff" = data-alvo - today date

    let differences = {};

    let days = (diff) / (1000 * 60 * 60 * 24);
    let hours = (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60);
    let minutes = (diff % (1000 * 60 * 60)) / (1000 * 60);
    let seconds = (diff % (1000 * 60)) / 1000;

    differences = {
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
      round: {
        days: (Math.round(days)),
        hours: zeroPad(Math.floor(hours)),
        minutes: zeroPad(Math.floor(minutes)),
        seconds: zeroPad(Math.floor(seconds)),
      }
    }


    return differences;
}