
function defaultDate(date){
    let serverTime = new Date(date);

    let currentDate = {
        now: serverTime.getTime(),
        day: serverTime.getDate(),
        month: serverTime.getMonth() + 1,
        hours: serverTime.getHours(),
        minutes: serverTime.getMinutes(),
        year: serverTime.getFullYear(),
        fullTime: serverTime,
        fullTimeLocal: serverTime.toLocaleTimeString(),
        fullTimeString: serverTime.toString(),
        GMT: serverTime.toString().split(" ")[5],
    }

    return currentDate;
}

export default defaultDate;