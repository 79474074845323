import React, { useEffect, useState, useRef } from 'react';
import Loader from "../components/Loader";
import Error from "../components/Error";
import ReactMarkdown from "react-markdown";
import MaskedInput from "react-text-mask";

const api = process.env.REACT_APP_API;

function Contact() {
    const [ page, setPage ] = useState([]);
    const [inputData, setInput] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
        reCAPTCHA: ''
    });

    useEffect(()=>{
        fetch(`${api}/contato`)
            .then(res => res.json())
            .then((data)=>{
                setPage(data);
            })
            .catch((error)=>{
                console.log("error", error);
            });
    }, []);


    
    const name = useRef();
    const email = useRef();
    const phone = useRef();
    const subject = useRef();
    const message = useRef();
    const reCAPTCHA = useRef();

    const form = useRef();
    const messageAlert = useRef();


    async function sendEmail(data){
        const headers = {
            "Content-Type": "application/json"
        }

        let objectDataFormat = data;

        fetch(`${api}/contact-servicemail`, {"method": "POST", headers, body: JSON.stringify(data) })
            .then((data)=>{
                if(data.status < 400){
                    let devSuccess = "Dados enviados com sucesso: "+ data;
                    let userSuccess = "Sua mensagem foi enviada com sucesso!";
                    console.log(devSuccess);

                    messageAlert.current.innerText = userSuccess;
                    messageAlert.current.classList.remove("alert--error");
                    messageAlert.current.classList.add("alert--success", "active");
                    postInCollectionList(objectDataFormat);
                    disableToSend();
                } else {
                    alertError(data);
                }
            })
            .catch((e)=>{
                console.log("catch", e);
                // alertError(e);
            });
    }

    function postInCollectionList(data){
        const headers = {
            "Content-type": "application/json"
        }

        fetch(`${api}/contatos-recebidos`, {"method": "POST", headers, body: JSON.stringify(data) })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.log(response, "check network tab (XHR) in inspector for details");
                }
            })
            .then((data)=>{
                if(data){
                    let devSuccess = "Dados postados: " + data;
                    console.log(devSuccess);
                }
            })
            .catch((e)=>{
                console.log(e);
            });
    }

    
    function alertError(e){
        let devError = "Falha ao enviar os dados, " + e.error || e;
            let userMessage = "Houver algum problema ao enviar seus dados";
            console.log(devError);

            messageAlert.current.innerText = userMessage;
            messageAlert.current.classList.add("alert--error", "active");
            let timeout = setTimeout(()=>{
                messageAlert.current.classList.remove("alert--error", "active");
            }, 10000);
            clearTimeout(timeout);
    }

    
    function disableToSend(){
        form.current.classList.add("disabled");
    }


    function validateEmail(value){
        let email_validation = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value);
        if(email_validation){
            return true;
        } else {
            return false;
        }
    }

    function validatePhoneNumber(value){
        let telephone_validation = (/^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/).test(value);
        if(telephone_validation){
            return true;
        } else {
            return false;
        }
    }

    function phoneNumberMask(userInput) {
        let numbers = userInput.match(/\d/g);
        let numberLength = 0;
        if (numbers) {
          numberLength = numbers.join("").length;
        }
      
        if (numberLength > 10) {
          return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        } else {
          return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        }
    }


    function handleChange(event){
        setInput({
            ...inputData,
            [event.target.name]: [event.target.value]
        });

        handleValidation();
    }

    function handleValidation(){
        let timeout = setTimeout(()=>{
            messageAlert.current.classList.remove("alert--error", "active");
        }, 2000);
        clearTimeout(timeout);

        if(name.current.value.length > 0){
            name.current.classList.add("valid");
            name.current.classList.remove("error");
        } else {
            name.current.classList.add("error");
            name.current.classList.remove("valid");
        }
        
        if(email.current.value.length > 0 && validateEmail(email.current.value)){
            email.current.classList.add("valid");
            email.current.classList.remove("error");
        } else {
            email.current.classList.add("error");
            email.current.classList.remove("valid");
        }
        
        if(phone.current.inputElement.value.length > 0 && validatePhoneNumber(phone.current.inputElement.value)){
            phone.current.inputElement.classList.add("valid");
            phone.current.inputElement.classList.remove("error");
        } else {
            phone.current.inputElement.classList.add("error");
            phone.current.inputElement.classList.remove("valid");
        }

        if(subject.current.value.length > 0){
            subject.current.classList.add("valid");
            subject.current.classList.remove("error");
        } else {
            subject.current.classList.add("error");
            subject.current.classList.remove("valid");
        }
        
        if(message.current.value.length > 0){
            message.current.classList.add("valid");
            message.current.classList.remove("error");
        } else {
            message.current.classList.add("error");
            message.current.classList.remove("valid");
        }
        
        /*Prevenção de BOT */
        if(reCAPTCHA.current.value.length > 0){
            reCAPTCHA.current.classList.add("valid");
            reCAPTCHA.current.classList.remove("error");
        } else {
            reCAPTCHA.current.classList.add("error");
            reCAPTCHA.current.classList.remove("valid");
        }
    }

    function handleSubmit(event){
        event.preventDefault();
        setInput({
            ...inputData,
            [event.target.name]: [event.target.value]
        });

        const countInputs = document.querySelectorAll(".form-row input, .form-row textarea, .form-row select");
        const checkedValidFields = document.querySelectorAll(".form-row input.valid, .form-row textarea.valid, .form-row select.valid");
        const filledData = {
            "nome": inputData.name[0], 
            "email": inputData.email[0], 
            "telefone": inputData.phone[0], 
            "assunto": inputData.subject[0], 
            "mensagem": inputData.message[0]
        };

        
        
        let subtract_reCAPTCHA = 1;
        if(checkedValidFields.length === (countInputs.length - subtract_reCAPTCHA)){
            sendEmail(filledData);
        } else if(inputData.reCAPTCHA[0]) {
            console.log("Bots shall not pass");
        } else {
            messageAlert.current.innerText = "Complete os campos";
            messageAlert.current.classList.add("alert--error");
            let timeout = setTimeout(()=>{
                messageAlert.current.classList.remove("alert--error", "active");
            }, 10000);
            clearTimeout(timeout);
        }
    }


    if(!page && page !== "error"){
        return <Loader />;
    } else if(page === "error"){
        return <Error />;
    }
    return (
        <>

            <div className="container">
                <div className="contact">
                    <div className="contact-text">
                        <div className="contact-title">
                            {page && page.titulo}
                        </div>
                        <div className="contact-content">
                            <ReactMarkdown source={page && page.texto_formatado} />
                        </div>
                    </div>
                    
                    <div className="cards-row">
                        <div className="card-contact">
                            <div className="contact-box">
                                <div className="address-ico"></div>
                                <h5>Endereço:</h5>
                                <p>
                                    {page && page.endereco && <a href={page.endereco}>{page.endereco}</a>}
                                </p>
                            </div>
                        </div>
                        <div className="card-contact">
                            <div className="contact-box">
                                <div className="contact-ico"></div>
                                <h5>Contato:</h5>
                                <p>
                                    {page && page.telefone && <a href={page.telefone}><strong>Telefone: </strong>{page.telefone}</a>}
                                    </p>
                            </div>
                        </div>
                        <div className="card-contact">
                            <div className="contact-box">
                                <div className="mail-ico"></div>
                                <h5>Para mais informações:</h5>
                                <p>
                                    {page && page.email && <a href={page.email}> <strong>Email:</strong> {page.email}</a>}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="google-map">
                        <div className="map">
                            <iframe title="google-frame" src={page && page.maps_embbed}></iframe>
                        </div>
                    </div>

                    <div className="contact-form">
                        <h2>Entre em contato</h2>
                        <form onSubmit={handleSubmit} ref={form}>
                            <ul className="form-row">
                                <li title="BOT ONLY" className="half-col newsletter__box newsletter__box--reCAPTCHA" style={{opacity: 0, width: 0, height: 0, padding: 0, margin: 0, position: "absolute"}} tabIndex="0">
                                    {/* Prevenção de BOT - INPUT falso*/}
                                    <input type="text" className="form-control__reCAPTCHA form-control" name="reCAPTCHA" placeholder="Seu Nome" ref={reCAPTCHA} onBlur={handleChange} onChange={handleChange} />
                                </li>

                                <li className="half-col">
                                    <input type="text" className="form-control" placeholder="Seu Nome" name="name" ref={name} onBlur={handleChange} onChange={handleChange} />
                                </li>
                                <li className="half-col">
                                    <input type="text" className="form-control" placeholder="Email" name="email" ref={email} onBlur={handleChange} onChange={handleChange} />
                                </li>
                                <li className="half-col">
                                    <MaskedInput
                                        mask={phoneNumberMask}
                                        className="form-control"
                                        placeholder="Telefone"
                                        guide={false}
                                        id="phone"
                                        name="phone"
                                        type="text"
                                        // ref={(phone) => { this.inputElement = phone; }}
                                        ref={ phone }
                                        onBlur={handleChange} 
                                        onChange={handleChange} 
                                    />
                                </li>
                                <li className="half-col">
                                    <input type="text" className="form-control" placeholder="Assunto" name="subject" ref={subject} onBlur={handleChange} onChange={handleChange} />
                                </li>
                                <li className="full-col">
                                    <textarea className="form-control" placeholder="Escreva sua mensagem" name="message" ref={message} onBlur={handleChange} onChange={handleChange} />
                                </li>
                                <li className="full-col">
                                    <button type="submit" className="button button-primary">Enviar mensagem</button>
                                </li>
                                <li className="full-col">
                                    <p className="alert" ref={messageAlert}></p>
                                </li>
                            </ul>
                        </form>
                    </div>

                </div>
            </div>

        </>
    );
}

export default Contact;
