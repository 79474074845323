import React from "react";


function Page404() {
    // const [page, setPage] = useState([]);

    // useEffect(()=>{
    //     const headers = {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     }
    //     const getPage = async () => {
    //         const response = await fetch("/errorpage", {headers});
    //         const data = await response.json();
    //         setPage(data);
    //     }

    //     getPage();
    // }, []);


    // if(!page && page !== "error"){
    //     return <Loader />;
    // } else if(page === "error"){
    //     return <Error />;
    // }
    
    return(
        <div className="page404-component">
            <section className="section error-404">
                <h1>404</h1>
                <h2> <em>Ooops!</em> bola na trave #<i className="fas fa-futbol"></i>#</h2>
                <p>Página não encontrada</p>
            </section>
        </div>
    );
}


export default Page404;