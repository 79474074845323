import React, { useEffect, useState } from 'react';
import Loader from "../components/Loader";
import Error from "../components/Error";
import ReactMarkdown from "react-markdown";


import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const api = process.env.REACT_APP_API;


const sliderSettings = {
    arrows: true,
    dots: true,
    infinite: true,
    autoplay: true, 
    autoplaySpeed: 5000,       
    speed: 1500,
    variableWidth: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings:{
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
  };

function About() {
    const [ page, setPage ] = useState([]);

    useEffect(()=>{
        fetch(`${api}/sobre`)
            .then(res => res.json())
            .then((data)=>{
                setPage(data);
            })
            .catch((error)=>{
                console.log("error", error);
            });
    }, []);
    if(!page && page !== "error"){
        return <Loader />;
    } else if(page === "error"){
        return <Error />;
    }
    return (
        <>
            <div className="container"> 
                <div className="about">
                    <div className="about-title">
                        {page && page.titulo_um}
                    </div>
                    <div className="about-text">                    
                        <ReactMarkdown source={page && page.detalhe_um} />                            
                    </div>
                    <div className="fotos-row">
                        <Slider {...sliderSettings}>
                        {page.fotos_do_clube && page.fotos_do_clube.map((fotos_do_clube, key) =>    
                            <div key={fotos_do_clube.id} className="foto-card">
                                <div className="card-thumb">
                                    
                                        <img 
                                            src={api + fotos_do_clube.url || ""} 
                                            alt={fotos_do_clube.alternativeText || fotos_do_clube.name}
                                        />
                                </div> 
                            </div>
                        )}
                        </Slider>
                    </div>
                    <div className="about-title">
                        {page && page.titulo_dois}
                    </div>
                    <div className="about-text">                    
                        <ReactMarkdown source={page && page.detalhe_dois} />                            
                    </div>

                    <div className="about-title">
                        {page && page.titulo_tres}
                    </div>
                    <div className="about-text">                    
                        <ReactMarkdown source={page && page.detalhe_tres} />   
                    </div>
                </div>
            </div>
        </>
    );
}

export default About;
