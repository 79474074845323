import React from "react";


function Error() {
    return(
        <div className="container">
            <p className="error">
                Falha ao carregar API!
            </p>
        </div>
    );
}


export default Error;