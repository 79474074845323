import React, { useEffect, useLayoutEffect, useState } from 'react';
import Loader from "../components/Loader";
import Error from "../components/Error";
import DateTime from "../components/DateTime";
import dateCompare from "../helpers/dateCompare";
import defaultDate from '../helpers/defaultDate';


const api = process.env.REACT_APP_API;

function NextGame() {
    const [ page, setPage ] = useState([]);
    const [ targetDate, setTargetDate ] = useState([ new Date().getTime() ]);

    useEffect(()=>{
        fetch(`${api}/proxima-partida`)
            .then(res => res.json())
            .then((data)=>{
                setPage(data);
            })
            .catch((error)=>{
                console.log(error);
                setPage("error");
            });
    }, []);
    
    
    let compareTargetDate = new Date(page.datahora).getTime() - new Date().getTime();

    // console.log("compareTargetDate", compareTargetDate);
    useLayoutEffect(()=>{
        let interval = setInterval(()=>{
            if(compareTargetDate >= 0){
                setTargetDate( compareTargetDate );
            } else {
                clearTimeout(interval);
                setTargetDate( compareTargetDate );
            }
        }, 1000);

        return () => clearTimeout(interval);
    }, [compareTargetDate]);


    if(!page && page !== "error"){
        return <Loader />;
    } else if(page === "error"){
        return <Error />;
    }


    return (        
        <div className="container">
            <div className="next-game-card">
                <h5 className="title">Próxima Partida</h5>
                <div className="nmw-wrap">
                    <div className="nmw-wrap__top">
                        <ul className="match-teams-vs">
                            <li className="team-logo">
                                {
                                    page.Times 
                                    && <img 
                                            src={api + page.Times.time_um.logo_do_time.url || ""} 
                                            alt={page.Times.time_um.logo_do_time.name || page.Times.logo_do_time.alternativeText}
                                        />
                                }                                        
                                <strong>
                                    {page.Times && page.Times.time_um.nome_do_time} 
                                </strong> 
                               
                            </li>
                            <li className="mvs">
                                <strong className="vs">VS</strong>
                            </li>
                            <li className="team-logo">
                                {
                                    page.Times 
                                    && <img 
                                            src={api + page.Times.time_dois.logo_do_time.url || ""} 
                                            alt={page.Times.time_dois.logo_do_time.name || page.Times.logo_do_time.alternativeText}
                                        />
                                }              
                                    <strong>
                                        {page.Times && page.Times.time_dois.nome_do_time}
                                    </strong> 
                            </li>
                        </ul>
                        
                         <ul className={`nmw-txt ${compareTargetDate > 0 ? "" : "is-over"}`}>
                            <li className="nmw-txt__description">{page.Descricao}</li>
                            <li className="nmw-txt__fullDate">
                                <DateTime date={page.datahora}/>
                            </li>
                            <li className="nmw-txt__fullhour">
                                {defaultDate(page.datahora).fullTimeLocal} {/* {defaultDate(page.datahora).GMT} */}
                            </li>
                            <li className="nmw-txt__location">{page.local}</li>
                        </ul> 
                        {compareTargetDate > 0 ?
                            "" :
                            <p className="nmw-txt__status">Evento terminado.</p>}
                    </div>

                    <div className="nmw-wrap__bottom">
                        {compareTargetDate > 0 ? <div className="defaultCountdown is-countdown">
                            <span className="countdown-row countdown-show">
                                <span className="countdown-section days">
                                    <span className="countdown-amount">{dateCompare( targetDate ).round.days}</span>
                                    <span className="countdown-period">Dias</span>
                                </span>
                                <span className="countdown-section hours">
                                    <span className="countdown-amount">{dateCompare( targetDate ).round.hours}</span>
                                    <span className="countdown-period">Horas</span>
                                </span>
                                <span className="countdown-section minutes">
                                    <span className="countdown-amount">{dateCompare( targetDate ).round.minutes}</span>
                                    <span className="countdown-period">Minutos</span>
                                </span>
                                <span className="countdown-section seconds">
                                    <span className="countdown-amount">{dateCompare( targetDate ).round.seconds}</span>
                                    <span className="countdown-period">Segundos</span>
                                </span>
                            </span>                        
                        </div> : <div className="defaultCountdown is-countdown">
                            <span className="countdown-row countdown-show">
                                <span className="countdown-section days">
                                    <span className="countdown-amount">--</span>
                                    <span className="countdown-period">Dias</span>
                                </span>
                                <span className="countdown-section hours">
                                    <span className="countdown-amount">--</span>
                                    <span className="countdown-period">Horas</span>
                                </span>
                                <span className="countdown-section minutes">
                                    <span className="countdown-amount">--</span>
                                    <span className="countdown-period">Minutos</span>
                                </span>
                                <span className="countdown-section seconds">
                                    <span className="countdown-amount">--</span>
                                    <span className="countdown-period">Segundos</span>
                                </span>
                            </span>                        
                        </div>}
                        
                        <div className="countdown-row countdown-action">
                            <button type="button" className="button button-primary">Saber Mais</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NextGame;
