import React, { useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";

const api = process.env.REACT_APP_API;

function News(props) {
    const [ page, setPage ] = useState([]);
    const { slug, limit, classes } = props;    

    useEffect(()=>{       
        fetch(`${api}/noticias?_sort=updated_at:ASC${slug ? "&id_nin="+slug : ""}${limit ? "&_limit="+limit : ""}`)
            .then(res => res.json())
            .then((data)=>{
                setPage(data);
            })
            .catch((error)=>{
                console.log("error", error);
            });
    }, [slug, limit]);

    return (
        <>
            <div className={classes ? classes : "container-bg"}>
                <div className={`news ${classes ? "" : "news-page"}`}>
                    <h2 className="block-title">Mais Notícias</h2>
                    {page && page.map((noticia) =>
                        noticia.noticia_ativa ?
                            <div key={noticia.id} className="news-bar">
                                <div className="news-thumb">
                                    <Link to={`/noticia/${noticia.id}`} >
                                        {(noticia.Imagem && 
                                        
                                            <img 
                                                src={api + noticia.Imagem.url || ""} 
                                                alt={noticia.Imagem.alternativeText || noticia.Imagem.name}
                                            />) || <div className="no-image" alt="Sem imagem"/>
                                        }
                                    </Link>
                                </div> 
                                <div className="news-infos">
                                    <h4 className="news-title">
                                        <Link to={`/noticia/${noticia.id}`} >
                                            {noticia.Titulo}
                                        </Link>
                                    </h4>                                
                                    <div className="news-details">
                                        <ReactMarkdown source={noticia.Detalhe} />
                                    </div>
                                </div>
                            </div>
                        : ""
                        )
                    }
                </div>
            </div>
        </>
    );
}

export default News;
