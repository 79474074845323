import React, { useLayoutEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import './common/scss/main.scss';


import { setMobileVersion } from './common/helpers/mobile';

import ScrollToTop from './common/components/ScrollToTop';

import Head from './common/components/Head';
import Header from './common/components/Header';
import Footer from './common/components/Footer';

import Home from './common/pages/Home';
import NewsList from './common/pages/NewsList';
import Contact from './common/pages/Contact';
import About from './common/pages/About';
import HallOfFame from './common/pages/HallOfFame';
import Team from './common/pages/Team';
import NewsSingle from './common/pages/subpages/NewsSingle';
import Page404 from './common/pages/Page404';
import AniversaryMember from './common/pages/AniversaryMember';

import Sponsors from './common/pages/Sponsors';
import Season from './common/pages/Season';


import Teste from './common/teste/Teste';


function App() {
  useLayoutEffect(()=>{
    let header = document.querySelector(".header");
    let before = header.scrollHeight || header.offsetHeight;
    
    function onScroll(){
      let body = document.querySelector(".App");
      let current = document.documentElement.scrollTop;
      if(current > before){
          body.classList.add("scrolled");
      } else {
          body.classList.remove("scrolled");
      }
    }
    
    
    window.addEventListener("DOMContentLoaded", ()=>{
      setMobileVersion("body");
    });
    window.addEventListener("resize", ()=>{
      setMobileVersion("body");
    });
    
    window.addEventListener("scroll", function(){
      onScroll();
    });
    
    return () => [window.removeEventListener("resize"), window.removeEventListener("scroll")];
  }, []);

  return (
    <Router>
      <ScrollToTop />
      
      <Head />
      <div className="App fohfa">
        <Header />
          <main>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/teste" component={Teste} />
              <Route exact path="/hall-da-fama" component={HallOfFame} />
              <Route exact path="/aniversariante" component={AniversaryMember}/>
              <Route exact path="/equipe" component={Team}/>
              <Route exact path="/contato" component={Contact} />
              <Route exact path="/historia-do-clube" component={About} />
              <Route exact path="/noticias" component={NewsList} />
              <Route exact path="/noticia/:slug" component={NewsSingle} />
              <Route exact path="/patrocinadores" component={Sponsors} />
              <Route exact path="/temporada" component={Season} />
              
              <Redirect path={["/sistema", "https://fohfaoi.com/index.php"]} to="/"/>
              <Route path="*" component={Page404}/>
              <Redirect to="404" />
            </Switch>
          </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
