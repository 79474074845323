import React from 'react';
import NewsUpdate from "./NewsUpdate";
import AniversaryMember from './AniversaryMember';
import NextGame from "./NextGame";

// const api = process.env.REACT_APP_API;

function Home() {
    return (
        <>
            <section className="section newsupdate">
                <NewsUpdate limit={6}/>
            </section>
            <section className="section aniversaryMember">
                <AniversaryMember pageType="home"/>
            </section>
            <section className="section nextgame">
                <NextGame />                
            </section>
        </>
    );
}

export default Home;
